import React from 'react';
import '../App.css';
// import './App_Mobile_415px.css';
import BackgroundImage from '../Image/Back_Ground.png';
import FEAImage from '../Image/FEA.png';
import CFDImage from '../Image/CFD.png';
import PAImage from '../Image/ProjectAnalysis.gif';
import DNDImage from '../Image/DesignAndDevelopment.png';
import MDImage from '../Image/MethodologyDevelopment1.png';
import PTMImage from '../Image/PhysicalTestMatching.gif';
import LogoHydra from '../Logo/LogoHydra.png';
import MenuIcon from '@mui/icons-material/Menu';
import { grey } from '@mui/material/colors';
import '../i18n';
import { Trans, useTranslation } from 'react-i18next';
import i18n from '../i18n';

export default class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: "",
            notification: null,
            notificationColor: "red",
            isButtonSubcribeDisable: true,

            isShowCatalog: false,

            showDesign3DButton: false,
            showMachining3DButton: false,
            showMoreSolutionsButton: false,
            showTrainingButton: false,
            showServicesHeaderButton: false,
            showSupportButton: false,

            expandedFEA: false,
            enableButtonFEA: true,
            expandedCFD: false,
            enableButtonCFD: true,
            expandedPA: false,
            enableButtonPA: true,
            expandedDND: false,
            enableButtonDND: true,
            expandedPTM: false,
            enableButtonPTM: true,
            expandedMD: false,
            enableButtonMD: true,

            activeButtonConsultation: true,
            activeButtonProposal: false,
            activeButtonCommunication: false,
            activeButtonExecution: false,
            activeButtonDeliverables: false,
            activeButtonNextsteps: false,

            windowWidth: window.innerWidth,

            showContactList: false,
        };
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateScreenSize);
        this.setState({ isThuongMobile: window.innerWidth <= 413 });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateScreenSize);
    }

    handleButtonReadMoreFEA = () => {
        this.setState(prevState => ({
            expandedFEA: !prevState.expandedFEA,
            enableButtonFEA: !prevState.enableButtonFEA
        }));
    }

    handleButtonReadMoreCFD = () => {
        this.setState(prevState => ({
            expandedCFD: !prevState.expandedCFD,
            enableButtonCFD: !prevState.enableButtonCFD
        }));
    }

    handleButtonReadMorePA = () => {
        this.setState(prevState => ({
            expandedPA: !prevState.expandedPA,
            enableButtonPA: !prevState.enableButtonPA
        }));
    }

    handleButtonReadMoreDND = () => {
        this.setState(prevState => ({
            expandedDND: !prevState.expandedDND,
            enableButtonDND: !prevState.enableButtonDND
        }));
    }

    handleButtonReadMorePTM = () => {
        this.setState(prevState => ({
            expandedPTM: !prevState.expandedPTM,
            enableButtonPTM: !prevState.enableButtonPTM
        }));
    }

    handleButtonReadMoreMD = () => {
        this.setState(prevState => ({
            expandedMD: !prevState.expandedMD,
            enableButtonMD: !prevState.enableButtonMD
        }));
    }

    handleButtonConsultation = () => {
        this.setState({ activeButtonConsultation: true })
        this.setState({ activeButtonProposal: false })
        this.setState({ activeButtonCommunication: false })
        this.setState({ activeButtonExecution: false })
        this.setState({ activeButtonDeliverables: false })
        this.setState({ activeButtonNextsteps: false })
    }

    handleButtonProposal = () => {
        this.setState({ activeButtonConsultation: false })
        this.setState({ activeButtonProposal: true })
        this.setState({ activeButtonCommunication: false })
        this.setState({ activeButtonExecution: false })
        this.setState({ activeButtonDeliverables: false })
        this.setState({ activeButtonNextsteps: false })
    }

    handleButtonCommunication = () => {
        this.setState({ activeButtonConsultation: false })
        this.setState({ activeButtonProposal: false })
        this.setState({ activeButtonCommunication: true })
        this.setState({ activeButtonExecution: false })
        this.setState({ activeButtonDeliverables: false })
        this.setState({ activeButtonNextsteps: false })
    }

    handleButtonExecution = () => {
        this.setState({ activeButtonConsultation: false })
        this.setState({ activeButtonProposal: false })
        this.setState({ activeButtonCommunication: false })
        this.setState({ activeButtonExecution: true })
        this.setState({ activeButtonDeliverables: false })
        this.setState({ activeButtonNextsteps: false })
    }

    handleButtonDeliverables = () => {
        this.setState({ activeButtonConsultation: false })
        this.setState({ activeButtonProposal: false })
        this.setState({ activeButtonCommunication: false })
        this.setState({ activeButtonExecution: false })
        this.setState({ activeButtonDeliverables: true })
        this.setState({ activeButtonNextsteps: false })
    }

    handleButtonNextsteps = () => {
        this.setState({ activeButtonConsultation: false })
        this.setState({ activeButtonProposal: false })
        this.setState({ activeButtonCommunication: false })
        this.setState({ activeButtonExecution: false })
        this.setState({ activeButtonDeliverables: false })
        this.setState({ activeButtonNextsteps: true })
    }

    handleInputChange = (e) => {
        this.setState({ userEmail: e.target.value });
    };

    handleSubcribeButton = async () => {
        this.setState({
            notification: "Sending email...",
            userEmail: "",
        });

        const { userEmail } = this.state;

        try {
            await fetch("https://hydra-cyborg.com/API/data/api/SendEmail.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json"
                },
                body: JSON.stringify({
                    user_email: userEmail,
                })
            })
                .then(res => res.json())
                .then(async response => {
                    if (response.result == "0") {

                        this.setState({ notification: response.message });

                        setTimeout(() => {
                            this.setState({ isButtonSubcribeDisable: true });
                            this.setState({ notificationColor: "red" });
                            this.setState({ notification: "" });
                        }, 3000);

                    } else if (response.result == "1") {

                        this.setState({
                            notification: response.message,
                            notificationColor: "green",
                        });

                        setTimeout(() => {
                            this.setState({ isButtonSubcribeDisable: true });
                            this.setState({ notificationColor: "red" });
                            this.setState({ notification: "" });
                        }, 3000);

                    } else if (response.result == "2") {

                        this.setState({ notification: response.message });

                        setTimeout(() => {
                            this.setState({ notification: "" });
                            this.setState({ isButtonSubcribeDisable: true });
                            this.setState({ notificationColor: "red" });
                        }, 3000);

                    }
                })
                .catch(error => {
                    this.setState({ notification: "Please try again !" });

                    setTimeout(() => {
                        this.setState({ notification: "" });
                        this.setState({ isButtonSubcribeDisable: true });
                        this.setState({ notificationColor: "red" });
                    }, 3000);
                    console.log("Lỗi:", error);
                });
        } catch (error) {
            this.setState({ notification: "System error, please try again!" });

            setTimeout(() => {
                this.setState({ notification: "" });
                this.setState({ isButtonSubcribeDisable: true });
                this.setState({ notificationColor: "red" });
            }, 3000);
            console.log("Lỗi:", error);
        };
    }

    // enableButtonSubcribe = () => {
    //     return (
    //         <button
    //             name="subcribe"
    //             className="EnableButtonSubcribe"
    //             onClick={this.handleSubcribeButton}
    //             disabled={false}
    //         >
    //             <Trans>
    //                 SUBCRIBE
    //             </Trans>
    //         </button>
    //     )
    // }

    // disableButtonSubcribe = () => {
    //     return (
    //         <button
    //             name="subcribe"
    //             className="DisableButtonSubcribe"
    //             disabled={this.state.isButtonSubcribeDisable}
    //         >
    //             <Trans>
    //                 SUBCRIBE
    //             </Trans>
    //         </button>
    //     )
    // }

    changeLanguage(lng) {
        console.log("Click change" + lng);
        this.setState({ languageState: lng });
        // const { i18n } = this.props;
        i18n.changeLanguage(lng); // Thay đổi ngôn ngữ
    };

    handleShowContactList = () => {
        this.setState(prevState => ({ showContactList: !prevState.showContactList }));
    };

    handleWhatsappButton = () => {
        window.location.href = "https://wa.me/84979630754";
    }

    handleMessengerButton = () => {
        window.location.href = "https://m.me/vanle090603";
    }

    handleZaloButton = () => {
        window.location.href = "https://zalo.me/84979630754";
    }

    handleMailButton = () => {
        window.location.href = "mailto:hydragroup.info@gmail.com";
    }

    handleSkypeButton = () => {
        // window.location.href = "skype:boy_never_die1811?chat";
        window.location.href = "skype:live:duong_tan_phuoc?chat";
    }

    handleLinkedInButton = () => {
        window.open("https://www.linkedin.com/in/hydra-group-5982b31a2/", "_blank");
    }

    handleYoutubeButton = () => {
        window.open("https://www.youtube.com/")
    }

    handleFacebookButton = () => {
        window.open("https://www.facebook.com/")
    }

    render() {
        return (
            <div>
                <div className="BackgroundImageContainer">
                    <img src="https://hydra-cyborg.com/API/data/image/Back_Ground.png" className="BackgroundImage" alt="BackgroundImage" />
                    <div className="ContainOverlayText">
                        <h3 className="HeaderBackground">
                            <Trans>
                                SIMULATION SERVICES
                            </Trans>
                        </h3>
                        <p className="ContentBackground">
                            <Trans>
                                Advanced VR/AR - FEA & CFD for better Production Engineering
                            </Trans>
                        </p>
                        {/* <a href="https://www.goengineer.com/simulation-services#" target="_blank" className="LinkButton">Read More...</a> */}
                        <p className="LinkButton">
                            <Trans>
                                Read More...
                            </Trans>
                        </p>
                    </div>
                </div>

                <div className="Promotional01">
                    <div className="ContainPromotional01Content">
                        <div className="Promotional01Header">
                            <Trans>
                                DESIGN BETTER PRODUCTS WITH CAE EXPERTS
                            </Trans>
                        </div>
                        <div className="ChangeFlexDirectionRow">
                            <div className="QuayVongVong">
                                <img src="https://hydra-cyborg.com/API/data/image/DesignBetter.png" className="DesignBetter" alt="DesignBetter" />
                            </div>
                            <div className="Promotional01ContentStyle">
                                <div className="Promotional01Content01">
                                    <Trans>
                                        Bringing an innovative product to life is no easy task. Engineers are tasked with creating something
                                        entirely unique while ensuring it works flawlessly every time.
                                    </Trans>
                                </div>
                                <div className="Promotional01Content02">
                                    <Trans>
                                        It's a significant challenge.
                                    </Trans>
                                </div>
                                <div className="Promotional01Content03">
                                    <Trans>
                                        How do you reduce these risks effectively? Overlooking them can lead to serious setbacks: delays in
                                        production, lost business opportunities, failures in real-world use, costly recalls, and reputational
                                        harm.
                                    </Trans>
                                </div>

                                <div className="Promotional01Content04">
                                    <Trans>
                                        With powerful of CAE, FEA, CFD, numerical modeling, virtual testing, or prototyping, the objective is
                                        the same: achieving smarter engineering decisions through advanced simulation technologies.
                                    </Trans>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Promotional03">
                    <div className="ContainPromotional03Content">
                        <div className="Promotional03Header">
                            <Trans>
                                SIMULATION SERVICE CAPABILITIES
                            </Trans>
                        </div>
                        <div className="ChangeFlexDirectionRowPromo03">
                            <div className="FEA">
                                <div className="ContainImageFEA">
                                    <img src="https://hydra-cyborg.com/API/data/image/FEA_Analysis.png" className="ImageFEA" alt="ImageFEA" />
                                </div>
                                <div className="TitleFEA">
                                    <Trans>
                                        FEA Analysis
                                    </Trans>
                                </div>
                                <div className="ContainButtonFEA">
                                    <button className="ButtonReadMoreFEA" onClick={this.handleButtonReadMoreFEA}>
                                        {this.state.expandedFEA ? "" : <Trans>Read more...</Trans>}
                                    </button>
                                </div>
                                {this.state.expandedFEA && (
                                    <>
                                        <div className="Promotional03Content01">
                                            <Trans>
                                                We provide professional Finite Element Analysis (FEA) simulation services to help you solve complex engineering challenges.
                                                Our expertise in tools like ANSYS, ABAQUS, and HyperWorks allows us to analyze structural strength, stress, thermal performance,
                                                and dynamic behavior with precision.Whether you need to optimize product designs, enhance durability, reduce material costs, or improve performance
                                                under real-world conditions, our FEA services deliver actionable insights. Partner with us to accelerate your design process, minimize risks,
                                                and achieve the best results for your projects.
                                            </Trans>
                                        </div>
                                        <div className="Promotional03Content02">

                                        </div>
                                        <div className="Promotional03Content03">

                                        </div>
                                        <div className="ContainButtonFEA">
                                            <button className="ButtonReadMoreFEA" onClick={this.handleButtonReadMoreFEA}>
                                                {this.state.expandedFEA ? <Trans>Read less.</Trans> : ""}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="CFD">
                                <div className="ContainImageCFD">
                                    <img src="https://hydra-cyborg.com/API/data/image/CFD_Analysis.png" className="ImageCFD" alt="ImageCFD" />
                                </div>
                                <div className="TitleCFD">
                                    <Trans>
                                        CFD Analysis
                                    </Trans>
                                </div>
                                <div className="ContainButtonCFD">
                                    <button className="ButtonReadMoreCFD" onClick={this.handleButtonReadMoreCFD}>
                                        {this.state.expandedCFD ? "" : <Trans>Read more...</Trans>}
                                    </button>
                                </div>
                                {this.state.expandedCFD && (
                                    <>
                                        <div className="Promotional03Content04">
                                            <Trans>
                                                We can achieve almost anything with simulation technology. With expertise in FEM, DEM, CFD, and skills in CAD/CAM/CAE/Programming,
                                                we provide comprehensive simulation solutions.
                                            </Trans>
                                        </div>
                                        <div className="Promotional03Content05">
                                            <Trans>
                                                For example, if you want to understand how the concrete mixing process works, we can use CFD (ANSYS Fluent) to simulate it. To enhance
                                                the strength of a bike frame, we can perform FEA to analyze stress and design reinforcement ribs. If reducing the cost and weight of a
                                                wind turbine is your goal, we can create an optimized simulation process using ANSYS or HyperWorks. For wave energy converters, we can analyze
                                                and optimize efficiency using ANSYS AQWA. Even for scenarios like impact analysis (e.g., car crashes) or drop tests (e.g., for iPhones), we can
                                                leverage tools like ANSYS Dynamic or ABAQUS Dynamic.
                                            </Trans>
                                        </div>
                                        <div className="Promotional03Content06">
                                            <Trans>
                                                Every physical phenomenon in the real world can be analyzed in a virtual environment, providing solutions tailored to your needs.
                                            </Trans>
                                        </div>
                                        <div className="ContainButtonCFD">
                                            <button className="ButtonReadMoreCFD" onClick={this.handleButtonReadMoreCFD}>
                                                {this.state.expandedCFD ? <Trans>Read less.</Trans> : ""}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Promotional04">
                    <div className="ContainPromotional04Content">
                        <div className="Promotional04Header">
                            <Trans>
                                SIMULATION SERVICE TYPES
                            </Trans>
                        </div>
                        <div className="ChangeFlexDirectionRowPromo04Line01">

                            {/* ---------- PROJECT ANALYSIS ---------- */}
                            <div className="ProjectAnalysis">
                                <div className="ContainImagePA">
                                    <img src="https://hydra-cyborg.com/API/data/image/Unity_GameDevelop.png" className="ImageProjectAnalysis" alt="ImageProjectAnalysis" />
                                </div>
                                <div className="TitleProjectAnalysis">
                                    <Trans>
                                        UNITY - GAME DEVELOP
                                    </Trans>
                                </div>
                                <div className="ContainButtonPA">
                                    <button className="ButtonReadMorePA" onClick={this.handleButtonReadMorePA}>
                                        {this.state.expandedPA ? "" : <Trans>Read more...</Trans>}
                                    </button>
                                </div>
                                {this.state.expandedPA && (
                                    <>
                                        <div className="Promotional04Content01">
                                            <Trans>
                                                Whether you're building casual mobile games, immersive virtual reality experiences, or large-scale open-world adventures,
                                                Unity3D delivers the tools and resources needed to turn ideas into reality efficiently. Allowing games to be deployed seamlessly
                                                across PC, consoles, mobile devices, and web browsers. Unity3D provides a robust physics engine, high-quality rendering, and a vast
                                                asset store, making it easier to bring creative visions to life.
                                            </Trans>
                                        </div>
                                        <div className="Promotional04Content02">
                                            <div className="Promotional04Content02Header">
                                                {/* Engineering Analysis */}
                                            </div>
                                            <div className="Promotional04Content02Body">
                                                <Trans>
                                                    Language : C#, WGSL, HLSL, Python, PHP
                                                </Trans>
                                            </div>
                                        </div>
                                        <div className="Promotional04Content03">
                                            <div className="Promotional04Content03Header">
                                                {/* Augment Your In-house Engineering */}
                                            </div>
                                            <div className="Promotional04Content03Body">
                                                <Trans>
                                                    3D modelling: Blender 4.0
                                                </Trans>
                                            </div>
                                            <div className="ContainButtonPA">
                                                <button className="ButtonReadMorePA" onClick={this.handleButtonReadMorePA}>
                                                    {this.state.expandedPA ? <Trans>Read less.</Trans> : ""}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            {/* ---------- PRODUCT DESIGN & DEVELOPMENT ---------- */}
                            <div className="ProductDesignAndDev">
                                <div className="ContainImageDND">
                                    <img src="https://hydra-cyborg.com/API/data/image/Prototype_EV.png" className="ImageProductDesignAndDev" alt="ImageProductDesignAndDev" />
                                </div>
                                <div className="TitleProductDesignAndDev">
                                    <Trans>
                                        Prototype - EV
                                    </Trans>
                                </div>
                                <div className="ContainButtonDND">
                                    <button className="ButtonReadMoreDND" onClick={this.handleButtonReadMoreDND}>
                                        {this.state.expandedDND ? "" : <Trans>Read more...</Trans>}
                                    </button>
                                </div>
                                {this.state.expandedDND && (
                                    <>
                                        <div className="Promotional04ContentDND01">
                                            <Trans>
                                                We offer comprehensive services for the design, simulation, and prototyping of electric vehicles (EVs). From conceptual design and 3D modeling
                                                to performance simulations and manufacturing prototypes, our team ensures every aspect of your EV project is optimized for efficiency, safety, and innovation.
                                            </Trans>
                                        </div>
                                        <div className="Promotional04ContentDND02">
                                            <div className="Promotional04ContentDND02Header">
                                                {/* Project Planning */}
                                            </div>
                                            <div className="Promotional04ContentDND02Body">
                                                <Trans>
                                                    Using advanced tools like CAD, FEA, and CFD, we analyze structural integrity, aerodynamics, and thermal management to deliver cutting-edge solutions.
                                                    Whether you're developing a new EV model or refining an existing design, we provide end-to-end support to bring your vision to reality and stay ahead
                                                    in the rapidly evolving electric vehicle industry.
                                                </Trans>
                                            </div>
                                        </div>
                                        {/* <div className="Promotional04ContentDND03">
                      <div className="Promotional04ContentDND03Header">
                        Reliability Growth
                      </div>
                      <div className="Promotional04ContentDND03Body">
                        Boundary diagrams, process flow, and failure modes effect analysis (FMEA).
                      </div>
                    </div>
                    <div className="Promotional04ContentDND04">
                      <div className="Promotional04ContentDND04Header">
                        Supplier Coordination
                      </div>
                      <div className="Promotional04ContentDND04Body">
                        Prototypes, capability assessment, and cost analysis.
                      </div>
                    </div>
                    <div className="Promotional04ContentDND05">
                      <div className="Promotional04ContentDND05Header">
                        Engineering, Cost, & Supplier Status
                      </div>
                      <div className="Promotional04ContentDND05Body">
                        Concept sheets and customized management dashboards.
                      </div>
                    </div>
                    <div className="Promotional04ContentDND06">
                      <div className="Promotional04ContentDND06Header">
                        Cost Forecasting & Tracking
                      </div>
                      <div className="Promotional04ContentDND06Body">
                        Financial planning, tracking, and cashflow analysis.
                      </div>
                    </div> */}
                                        <div className="Promotional04ContentDND07">
                                            {/* <div className="Promotional04ContentDND07Header">
                        We Do Front End Engineering Design (FEED)
                      </div>
                      <div className="Promotional04ContentDND07Body01">
                        Cost-effective product development programs rely upon FEED to minimize total expenditures.
                        Although the initial investment is typically higher (more up-front engineering),
                        the process generally leads to reduced cost of product development and final products.
                      </div>
                      <div className="Promotional04ContentDND07Body02">
                        FEED capabilities:
                      </div>
                      <div className="Promotional04ContentDND07Body03">
                        • Planning/costing
                      </div>
                      <div className="Promotional04ContentDND07Body04">
                        • Concept design/trade studies
                      </div>
                      <div className="Promotional04ContentDND07Body05">
                        • Optimization/DOE
                      </div>
                      <div className="Promotional04ContentDND07Body06">
                        • FMEA/DVP&R support
                      </div> */}
                                            <div className="ContainButtonDND">
                                                <button className="ButtonReadMoreDND" onClick={this.handleButtonReadMoreDND}>
                                                    {this.state.expandedDND ? <Trans>Read less.</Trans> : ""}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        {/* ---------- PHYSICAL TEST MATCHING ---------- */}
                        <div className="ChangeFlexDirectionRowPromo04Line02">
                            <div className="PhysicalTestMatching">
                                <div className="ContainImagePTM">
                                    <img src="https://hydra-cyborg.com/API/data/image/Unity_VR.png" className="ImagePhysicalTestMatching" alt="ImagePhysicalTestMatching" />
                                </div>
                                <div className="TitlePhysicalTestMatching">
                                    <Trans>
                                        UNITY - VR
                                    </Trans>
                                </div>
                                <div className="ContainButtonPTM">
                                    <button className="ButtonReadMorePTM" onClick={this.handleButtonReadMorePTM}>
                                        {this.state.expandedPTM ? "" : <Trans>Read more...</Trans>}
                                    </button>
                                </div>
                                {this.state.expandedPTM && (
                                    <>
                                        <div className="Promotional04ContentPTM01">
                                            <Trans>
                                                We offer cutting-edge Unity and VR development services to help bring your ideas to life. Using Unity's powerful game engine, we create immersive
                                                virtual reality experiences tailored to your needs, whether for gaming, training simulations, architectural visualization, or interactive marketing.
                                            </Trans>
                                        </div>
                                        <div className="Promotional04ContentPTM02">
                                            <div className="Promotional04ContentPTM02Header">
                                                {/* Material Characterization */}
                                            </div>
                                            <div className="Promotional04ContentPTM02Body">
                                                <Trans>
                                                    Our expertise ensures seamless performance, realistic environments, and engaging interactions that captivate your audience. From concept to deployment,
                                                    we work closely with you to deliver innovative VR solutions that exceed expectations and provide lasting impact. Let us help you transform your vision
                                                    into a dynamic virtual experience!
                                                </Trans>
                                            </div>
                                        </div>
                                        <div className="Promotional04ContentPTM03">
                                            {/* <div className="Promotional04ContentPTM03Header">
                        Augment Your In-house Engineering
                      </div>
                      <div className="Promotional04ContentPTM03Body">
                        Already comfortable with your processs? We'll pick up your methods and standards
                        as if we were a member of your team. Whether you need more heads or more
                        computers, we're there when you need us to keep your pipeline moving.
                      </div> */}
                                            <div className="ContainButtonPTM">
                                                <button className="ButtonReadMorePTM" onClick={this.handleButtonReadMorePTM}>
                                                    {this.state.expandedPTM ? <Trans>Read less.</Trans> : ""}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            {/* ---------- METHODOLOGY DEVELOPMENT ---------- */}
                            <div className="MethodologyDev">
                                <div className="ContainImageMD">
                                    <img src="https://hydra-cyborg.com/API/data/image/PCB_DesignPrototype.png" className="ImageMethodologyDev" alt="ImageMethodologyDev" />
                                </div>
                                <div className="TitleMethodologyDev">
                                    <Trans>
                                        PCB - Design Prototype
                                    </Trans>
                                </div>
                                <div className="ContainButtonMD">
                                    <button className="ButtonReadMoreMD" onClick={this.handleButtonReadMoreMD}>
                                        {this.state.expandedMD ? "" : <Trans>Read more...</Trans>}
                                    </button>
                                </div>
                                {this.state.expandedMD && (
                                    <>
                                        <div className="Promotional04ContentMD01">
                                            <Trans>
                                                We specialize in designing and manufacturing custom electronic circuits using AVR, PIC, STM32, ESP32, and embedded systems like Raspberry Pi. Our services include:
                                            </Trans>
                                        </div>
                                        <div className="Promotional04ContentMD02">
                                            <div className="Promotional04ContentMD02Body">
                                                <Trans>
                                                    Tailored circuit design to meet your project needs.
                                                </Trans>
                                            </div>
                                        </div>
                                        <div className="Promotional04ContentMD03">
                                            <div className="Promotional04ContentMD02Body">
                                                <Trans>
                                                    Fabrication of high-quality 2-layer PCBs with precision.
                                                </Trans>
                                            </div>
                                        </div>
                                        <div className="Promotional04ContentMD04">
                                            <div className="Promotional04ContentMD02Body">
                                                <Trans>
                                                    Seamless integration of embedded systems and microcontrollers.
                                                </Trans>
                                            </div>
                                        </div>
                                        <div className="Promotional04ContentMD05">
                                            <div className="Promotional04ContentMD02Body">
                                                <Trans>
                                                    Let us help bring your ideas to life with our expertise in electronics and innovation.
                                                </Trans>
                                            </div>
                                            <div className="ContainButtonMD">
                                                <button className="ButtonReadMoreMD" onClick={this.handleButtonReadMoreMD}>
                                                    {this.state.expandedMD ? <Trans>Read less.</Trans> : ""}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="Promotional05Background"> */}
                <div className="Promotional05">
                    <div className="TitlePromotional05">
                        <Trans>
                            Simulation Services Process
                        </Trans>
                    </div>
                    <div className="TaskbarPromotional05">
                        <div className="Consultation">
                            <button
                                className={`ButtonPromotional05 ${this.state.activeButtonConsultation ? "active" : ""}`}
                                onClick={this.handleButtonConsultation}
                            >
                                <Trans>
                                    CONSULTATION
                                </Trans>
                            </button>
                        </div>
                        <div className="Proposal">
                            <button
                                className={`ButtonPromotional05 ${this.state.activeButtonProposal ? "active" : ""}`}
                                onClick={this.handleButtonProposal}
                            >
                                <Trans>
                                    PROPOSAL
                                </Trans>
                            </button>
                        </div>
                        <div className="Communication">
                            <button
                                className={`ButtonPromotional05 ${this.state.activeButtonCommunication ? "active" : ""}`}
                                onClick={this.handleButtonCommunication}
                            >
                                <Trans>
                                    COMMUNICATION
                                </Trans>
                            </button>
                        </div>
                        <div className="Execution">
                            <button
                                className={`ButtonPromotional05 ${this.state.activeButtonExecution ? "active" : ""}`}
                                onClick={this.handleButtonExecution}
                            >
                                <Trans>
                                    EXECUTION
                                </Trans>
                            </button>
                        </div>
                        <div className="Deliverables">
                            <button
                                className={`ButtonPromotional05 ${this.state.activeButtonDeliverables ? "active" : ""}`}
                                onClick={this.handleButtonDeliverables}
                            >
                                <Trans>
                                    DELIVERABLES
                                </Trans>
                            </button>
                        </div>
                        <div className="Nextsteps">
                            <button
                                className={`ButtonPromotional05 ${this.state.activeButtonNextsteps ? "active" : ""}`}
                                onClick={this.handleButtonNextsteps}
                            >
                                <Trans>
                                    NEXTSTEPS
                                </Trans>
                            </button>
                        </div>
                    </div>

                    {/* ---------- CONSULTATION ---------- */}
                    {this.state.activeButtonConsultation && (
                        <div className="Promotional05Content">
                            <div className="ContainImageConsultation">
                                <img src="https://hydra-cyborg.com/API/data/image/ConsultationImage.png" className="ConsultationImage" alt="ConsultationImage" />
                            </div>
                            <div className="ConsultationContent">
                                <div className="ConsultationContentHeader">
                                    <Trans>
                                        CONSULTATION
                                    </Trans>
                                </div>
                                <div className="ConsultationContentTitle">
                                    <Trans>
                                        It Begins with a Conversation
                                    </Trans>
                                </div>
                                <div className="ConsultationContent01">
                                    <Trans>
                                        Your engineering and business needs are unique, and there are many ways that GoEngineer can help.
                                        We can integrate simple analysis, design experimentation, and virtual testing involving a variety
                                        of physics simulation at varying levels into your own processes and operations.
                                    </Trans>
                                </div>
                                <div className="ConsultationContent02">
                                    <Trans>
                                        After an in-depth technical discussion, we'll provide the best personalized
                                        solutions from our service catalog to meet your goals.
                                    </Trans>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* ---------- PROPOSAL ---------- */}
                    {this.state.activeButtonProposal && (
                        <div className="Promotional05Content">
                            <div className="ContainImageProposal">
                                <img src="https://hydra-cyborg.com/API/data/image/ConsultationImage.png" className="ProposalImage" alt="ProposalImage" />
                            </div>
                            <div className="ProposalContent">
                                <div className="ProposalContentHeader">
                                    PROPOSAL
                                </div>
                                <div className="ProposalContentTitle">
                                    Know Exactly What You're Getting
                                </div>
                                <div className="ProposalContent01">
                                    GoEngineer will build a detailed proposal reiterating your interests and what we'll do to meet them.
                                    This document will include:
                                </div>
                                <div className="ProposalContent02">
                                    • Bulleted project scope, including analysis methods, purposes, and outputs
                                </div>
                                <div className="ProposalContent03">
                                    • Phased timeline and manpower (estimated)
                                </div>
                                <div className="ProposalContent04">
                                    • List of deliverables, obligations, and extended offerings
                                </div>
                                <div className="ProposalContent05">
                                    • Cost breakdown
                                </div>
                            </div>
                        </div>
                    )}

                    {/* ---------- COMMUNICATION ---------- */}
                    {this.state.activeButtonCommunication && (
                        <div className="Promotional05Content">
                            <div className="ContainImageCommunication">
                                <img src="https://hydra-cyborg.com/API/data/image/ConsultationImage.png" className="CommunicationImage" alt="CommunicationImage" />
                            </div>
                            <div className="CommunicationContent">
                                <div className="CommunicationContentHeader">
                                    COMMUNICATION
                                </div>
                                <div className="CommunicationContentTitle01">
                                    Dedicated Points of Contact
                                </div>
                                <div className="CommunicationContent01">
                                    You will have a lead engineer and an overseeing engineering manager assigned to your project,
                                    with uninterrupted and open lines of communication.
                                </div>
                                <div className="CommunicationContentTitle02">
                                    Awareness from Beginning to End
                                </div>
                                <div className="CommunicationContent02">
                                    We recommend weekly web meetings with your GoEngineer project lead and engineering manager to discuss
                                    the progress and direction of your project. These meetings can be extremely valuable, especially upon
                                    surprising analysis results.
                                </div>
                                <div className="CommunicationContentTitle03">
                                    Have CAE, Will Travel
                                </div>
                                <div className="CommunicationContent03">
                                    GoEngineer personnel are also available for travel to in-person meetings with you and/or your customer.
                                </div>
                            </div>
                        </div>
                    )}

                    {/* ---------- EXECUTION ---------- */}
                    {this.state.activeButtonExecution && (
                        <div className="Promotional05Content">
                            <div className="ContainImageExecution">
                                <img src="https://hydra-cyborg.com/API/data/image/ConsultationImage.png" className="ExecutionImage" alt="ExecutionImage" />
                            </div>
                            <div className="ExecutionContent">
                                <div className="ExecutionContentHeader">
                                    EXECUTION
                                </div>
                                <div className="ExecutionContentTitle01">
                                    Know Exactly What You're Getting
                                </div>
                                <div className="ExecutionContent01">
                                    We build high-quality simulations to customer specifications using our extensive CAE software portfolio:
                                </div>
                                <a href="https://www.google.com" target="_blank" className="BlueTextLink">
                                    <div className="ExecutionContent02">
                                        • Abaqus FEA
                                    </div>
                                </a>
                                <a href="https://www.google.com" target="_blank" className="BlueTextLink">
                                    <div className="ExecutionContent03">
                                        • fe-safe
                                    </div>
                                </a>
                                <a href="https://www.google.com" target="_blank" className="BlueTextLink">
                                    <div className="ExecutionContent04">
                                        • Tosca Structure
                                    </div>
                                </a>
                                <a href="https://www.google.com" target="_blank" className="BlueTextLink">
                                    <div className="ExecutionContent05">
                                        • Isight & SIMULIA Execution Engine
                                    </div>
                                </a>
                                <div className="ExecutionContent06">
                                    • FEMFAT Fatigue Software
                                </div>
                                <div className="ExecutionContent07">
                                    • Simpack
                                </div>
                                <a href="https://www.google.com" target="_blank" className="BlueTextLink">
                                    <div className="ExecutionContent08">
                                        • SOLIDWORKS Simulation
                                    </div>
                                </a>
                                <a href="https://www.google.com" target="_blank" className="BlueTextLink">
                                    <div className="ExecutionContent09">
                                        • SOLIDWORKS Flow Simulation
                                    </div>
                                </a>
                                <a href="https://www.google.com" target="_blank" className="BlueTextLink">
                                    <div className="ExecutionContent10">
                                        • SOLIDWORKS Plastics
                                    </div>
                                </a>
                                <a href="https://www.google.com" target="_blank" className="BlueTextLink">
                                    <div className="ExecutionContent11">
                                        • 3DEXPERIENCE STRUCTURAL
                                    </div>
                                </a>
                                <a href="https://www.google.com" target="_blank" className="BlueTextLink">
                                    <div className="ExecutionContent12">
                                        • 3DEXPERIENCE FLUIDS
                                    </div>
                                </a>
                                <div className="ExecutionContent13">
                                    • Ask for additional codes
                                </div>
                                <div className="ExecutionContentTitle02">
                                    Big Computing Resources for Fast Results
                                </div>
                                <div className="ExecutionContent14">
                                    With high-performance local and cloud computing capabilities, we can support rapid iteration on very
                                    large analyses, like high-fidelity, multistep nonlinear static analysis, or full vehicle crash simulations.
                                </div>
                            </div>
                        </div>
                    )}

                    {/* ---------- DELIVERABLES ---------- */}
                    {this.state.activeButtonDeliverables && (
                        <div className="Promotional05Content">
                            <div className="ContainImageDeliverables">
                                <img src="https://hydra-cyborg.com/API/data/image/ConsultationImage.png" className="DeliverablesImage" alt="DeliverablesImage" />
                            </div>
                            <div className="DeliverablesContent">
                                <div className="DeliverablesContentHeader">
                                    DELIVERABLES
                                </div>
                                <div className="DeliverablesContentTitle">
                                    Everything You Need for Better Decision Making
                                </div>
                                <div className="DeliverablesContent01">
                                    The GoEngineer CAE consulting team can produce a wide range of deliverables for you,
                                    as scoped out in your proposal:
                                </div>
                                <div className="DeliverablesContent02">
                                    • Simulation and technical presentations
                                </div>
                                <div className="DeliverablesContent03">
                                    • Image, video, and 3D interactive results
                                </div>
                                <div className="DeliverablesContent04">
                                    • Optimized 3D topology or design parameters
                                </div>
                                <div className="DeliverablesContent05">
                                    • Design/engineering recommendations
                                </div>
                                <div className="DeliverablesContent06">
                                    • Accounting of resources
                                </div>
                                <div className="DeliverablesContent07">
                                    • Functioning FEA & CFD models
                                </div>
                                <div className="DeliverablesContent08">
                                    • Solver output databases
                                </div>
                                <div className="DeliverablesContent09">
                                    • Documentation of methodologies
                                </div>
                            </div>
                        </div>
                    )}

                    {/* ---------- NEXTSTEPS ---------- */}
                    {this.state.activeButtonNextsteps && (
                        <div className="Promotional05Content">
                            <div className="ContainImageNextsteps">
                                <img src="https://hydra-cyborg.com/API/data/image/ConsultationImage.png" className="NextstepsImage" alt="NextstepsImage" />
                            </div>
                            <div className="NextstepsContent">
                                <div className="NextstepsContentHeader">
                                    NEXTSTEPS
                                </div>
                                <div className="NextstepsContentTitle">
                                    Building Upon Success
                                </div>
                                <div className="NextstepsContent01">
                                    Some projects may be complete upon delivery, but it doesn't have to stop there.
                                    Additional steps may include:
                                </div>
                                <div className="NextstepsContent02">
                                    • Project retrospective
                                </div>
                                <div className="NextstepsContent03">
                                    • Customer interface
                                </div>
                                <div className="NextstepsContent04">
                                    • Additional iterations and studies
                                </div>
                                <div className="NextstepsContent05">
                                    • Transfer of analysis files
                                </div>
                                <div className="NextstepsContent06">
                                    • Training in methodologies used
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="Promotional06">
                    <div className="TitlePromotional06">
                        <Trans>
                            INDUSTRIES SERVED
                        </Trans>
                    </div>
                    <div className="Promotional06Header">
                        <Trans>
                            WHO CAN WE HELP ?
                        </Trans>
                    </div>
                    <div className="ContainPromotional06Content">
                        <div className="ContainPromotional06Image">
                            <img src="https://hydra-cyborg.com/API/data/image/IndustriesServed.png" className="Promotional06Image" alt="Promotional06Image" />
                        </div>
                        <div className="Promotional06Content">
                            <div className="Promotional06Content01">
                                <Trans>
                                    We work across a broad range of industries that includes automotive, energy, oil and gas,
                                    aerospace and defense, civil and structural engineering, metals, machining and manufacturing,
                                    consumer goods and appliances, healthcare, and biomechanics; helping customers to leverage
                                    existing legacy and emerging simulation technologies.
                                </Trans>
                            </div>
                            <div className="Promotional06Content02">
                                <Trans>
                                    We have experts in engineering, mathematics, and physics with proven experience in mechanical and
                                    structural engineering, metallurgy, computational fluid dynamics, high & low-frequency electromagnetism,
                                    multi-physics, multi-body dynamics, acoustics, tolerance management, and additive manufacturing.
                                </Trans>
                            </div>
                            <div className="ContainPromotional06Button">
                                <button className="Promotional06Button">
                                    <Trans>
                                        REQUEST MORE INFORMATION
                                    </Trans>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="Promotional07">
          <div className="TitlePromotional07">
            Additional Resources
          </div>
          <div className="Promotional07Header">
            Take Advantage of GoEngineer’s Extensive Knowledge Base and Resources
          </div>
          <div className="ContainPromotional07Content01">
            <div className="FindASolution">
              <div className="ContainFindASolutionImage">
                <img src={FEAImage} className="FindASolutionImage" alt="FindASolutionImage" />
              </div>
              <div className="FindASolutionHeader">
                FIND A SOLUTION
              </div>
              <div className="FindASolutionContent">
                Our Robus Knowledge Base contains over 12,000 resources to help answer your product design questions.
                From basic CAD questions to in-depth-guides and tutorials, find your solution here.
              </div>
            </div>

            <div className="ProfessionalTraining">
              <div className="ContainProfessionalTrainingImage">
                <img src={FEAImage} className="ProfessionalTrainingImage" alt="ProfessionalTrainingImage" />
              </div>
              <div className="ProfessionalTrainingHeader">
                PROFESSIONAL TRAINING
              </div>
              <div className="ProfessionalTrainingContent">
                Improve your skills with professional training and certifications in SOLIDWORKS, CAM, 3D Printing, and 3D
                Scanning offered four ways: self-paced, online, on-site, or in-classroom.
              </div>
            </div>

            <div className="Blog">
              <div className="ContainBlogImage">
                <img src={FEAImage} className="BlogImage" alt="BlogImage" />
              </div>
              <div className="BlogHeader">
                BLOG
              </div>
              <div className="BlogContent">
                #1 Technical Resource Worldwide - Right at you fingertips. Search or browse through hundreds of SOLIDWORKS tips
                & tricks, additive manufacturing product development, announcements, how-to guides, and tutorials.
              </div>
            </div>

            <div className="YoutubeChannel">
              <div className="ContainYoutubeChannelImage">
                <img src={FEAImage} className="YoutubeChannelImage" alt="YoutubeChannelImage" />
              </div>
              <div className="YoutubeChannelHeader">
                YOUTUBE CHANNEL
              </div>
              <div className="YoutubeChannelContent">
                Our YouTube channel hosts hundreds of educational tutorials, product demonstrations, recorded webinars,
                and best practices for all of our products and services.
              </div>
            </div>

          </div>

          <div className="ContainPromotional07Content02">
            <div className="OnlineStore">
              <div className="ContainOnlineStoreImage">
                <img src={FEAImage} className="OnlineStoreImage" alt="OnlineStoreImage" />
              </div>
              <div className="OnlineStoreHeader">
                ONLINE STORE
              </div>
              <div className="OnlineStoreContent">
                Order 3D printing materials and consumables, enroll in SOLIDWORKS training classes, and buy official
                GoEngineer gear directly from out online store.
              </div>
            </div>

            <div className="Webinars">
              <div className="ContainWebinarsImage">
                <img src={FEAImage} className="WebinarsImage" alt="WebinarsImage" />
              </div>
              <div className="WebinarsHeader">
                WEBINARS
              </div>
              <div className="WebinarsContent">
                Our engineering webinars are hosted by some of the top expoerts in the industry. They are always recorded,
                always free, and always offer a live Q&A.
              </div>
            </div>

            <div className="PrintingServices3D">
              <div className="Contain3DPrintingServicesImage">
                <img src={FEAImage} className="PrintingServices3DImage" alt="PrintingServices3DImage" />
              </div>
              <div className="PrintingServices3DHeader">
                3D PRINTING SERVICES
              </div>
              <div className="PrintingServices3DContent">
                Need to 3D print a part? Out Additive Manufacturing experts will 3D print your part and deliver it to you using
                the latest technology on one of our professional FDM, PolyJet and SL 3D printers.
              </div>
            </div>

            <div className="SimulationServices">
              <div className="ContainSimulationServicesImage">
                <img src={FEAImage} className="SimulationServicesImage" alt="SimulationServicesImage" />
              </div>
              <div className="SimulationServicesHeader">
                SIMULATION SERVICES
              </div>
              <div className="SimulationServicesContent">
                GoEngineer's CAE consultants help you design better products faster. Our advanced FEA & CFD services provide
                critical engineering insight to world-class OEMs, suppliers, and start-ups alike.
              </div>
            </div>

          </div>
        </div> */}


            </div>
        )
    }
}