import React from 'react';
import './App.css';
// import './App_Mobile_415px.css';
import BackgroundImage from './Image/BackgroundImage.png';
import FEAImage from './Image/FEA.png';
import CFDImage from './Image/CFD.png';
import PAImage from './Image/ProjectAnalysis.gif';
import DNDImage from './Image/DesignAndDevelopment.png';
import MDImage from './Image/MethodologyDevelopment1.png';
import PTMImage from './Image/PhysicalTestMatching.gif';
import LogoHydra from './Logo/LogoHydra.png';
import MenuIcon from '@mui/icons-material/Menu';
import { grey } from '@mui/material/colors';
import './i18n';
import { Trans, useTranslation } from 'react-i18next';
import i18n from './i18n';
import Homepage from './Component/Homepage';
import { Route, Routes } from 'react-router-dom';
import Architecture from './Component/Design3D/Architecture';
import Mechanical from './Component/Design3D/Mechanical';
import Mechatronic from './Component/Design3D/Mechatronic';
import Product from './Component/Design3D/Product';
import CNC3DMilling from './Component/Machining3D/CNC3DMilling';
import DLP3DPrinting from './Component/Machining3D/DLP3DPrinting';
import Filament3DPrinting from './Component/Machining3D/Filament3DPrinting';
import LaserEngraving from './Component/Machining3D/LaserEngraving';
import PlasmaCutting from './Component/Machining3D/PlasmaCutting';
import RobotArm from './Component/Machining3D/RobotArm';
import ImageProcessing from './Component/MoreSolutions/ImageProcessing';
import MoreSolutionsAI from './Component/MoreSolutions/MoreSolutionsAI';
import Design3D from './Component/Services/Design3D';
import ProductDesign from './Component/Services/ProductDesign';
import Prototype from './Component/Services/Prototype';
import Simulation from './Component/Services/Simulation';
import VR_AR from './Component/Services/VR_AR';
import SetupCluster from './Component/Support/SetupCluster';
import SetupNetwork from './Component/Support/SetupNetwork';
import SetupServer from './Component/Support/SetupServer';
import SupportSystem from './Component/Support/SupportSystem';
import Abaqus from './Component/Training/Abaqus';
import Ansys from './Component/Training/Ansys';
import Catia from './Component/Training/Catia';
import ProgrammingLanguage from './Component/Training/ProgrammingLanguage';
import TrainingAI from './Component/Training/TrainingAI';
import Unity from './Component/Training/Unity';

// const WindowWidth = window.innerWidth;
// const WindowHeight = window.innerHeight;

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: "",
      notification: null,
      notificationColor: "red",
      isButtonSubcribeDisable: true,


      isThuongMobile: false,
      isShowCatalog: false,
      showDesign3DButton: false,
      showMachining3DButton: false,
      showMoreSolutionsButton: false,
      showTrainingButton: false,
      showServicesHeaderButton: false,
      showSupportButton: false,
    };
  }

  enableButtonSubcribe = () => {
    return (
      <button
        name="subcribe"
        className="EnableButtonSubcribe"
        onClick={this.handleSubcribeButton}
        disabled={false}
      >
        <Trans>
          SUBCRIBE
        </Trans>
      </button>
    )
  }

  disableButtonSubcribe = () => {
    return (
      <button
        name="subcribe"
        className="DisableButtonSubcribe"
        disabled={this.state.isButtonSubcribeDisable}
      >
        <Trans>
          SUBCRIBE
        </Trans>
      </button>
    )
  }

  updateScreenSize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      isThuongMobile: window.innerWidth <= 481, // Condition show mobile screen
    });
  };

  handleShowCatalog = () => {
    this.setState((prevState) => ({ isShowCatalog: !prevState.isShowCatalog }));
  };

  handleDesign3DButton = () => {
    this.setState(prevState => ({
      showDesign3DButton: !prevState.showDesign3DButton,
      showMachining3DButton: false,
      showMoreSolutionsButton: false,
      showTrainingButton: false,
      showServicesHeaderButton: false,
      showSupportButton: false,
    }));
  }

  handleMachining3DButton = () => {
    this.setState(prevState => ({
      showDesign3DButton: false,
      showMachining3DButton: !prevState.showMachining3DButton,
      showMoreSolutionsButton: false,
      showTrainingButton: false,
      showServicesHeaderButton: false,
      showSupportButton: false,
    }));
  }

  handleMoreSolutionsButton = () => {
    this.setState(prevState => ({
      showDesign3DButton: false,
      showMoreSolutionsButton: !prevState.showMoreSolutionsButton,
      showMachining3DButton: false,
      showTrainingButton: false,
      showServicesHeaderButton: false,
      showSupportButton: false,
    }));
  }

  handleTrainingButton = () => {
    this.setState(prevState => ({
      showDesign3DButton: false,
      showTrainingButton: !prevState.showTrainingButton,
      showMachining3DButton: false,
      showMoreSolutionsButton: false,
      showServicesHeaderButton: false,
      showSupportButton: false,
    }));
  }

  handleServicesHeaderButton = () => {
    this.setState(prevState => ({
      showDesign3DButton: false,
      showServicesHeaderButton: !prevState.showServicesHeaderButton,
      showMachining3DButton: false,
      showMoreSolutionsButton: false,
      showTrainingButton: false,
      showSupportButton: false,
    }));
  }

  handleSupportButton = () => {
    this.setState(prevState => ({
      showDesign3DButton: false,
      showSupportButton: !prevState.showSupportButton,
      showMachining3DButton: false,
      showMoreSolutionsButton: false,
      showTrainingButton: false,
      showServicesHeaderButton: false,
    }));
  }

  handleContactButton = () => {
    window.location.href = "https://info.hydra-cyborg.com";
  };

  changeLanguage(lng) {
    console.log("Click change" + lng);
    this.setState({ languageState: lng });
    // const { i18n } = this.props;
    i18n.changeLanguage(lng); // Thay đổi ngôn ngữ
  };

  handleShowContactList = () => {
    this.setState(prevState => ({ showContactList: !prevState.showContactList }));
  };

  handleWhatsappButton = () => {
    window.location.href = "https://wa.me/84979630754";
  }

  handleMessengerButton = () => {
    window.location.href = "https://m.me/vanle090603";
  }

  handleZaloButton = () => {
    window.location.href = "https://zalo.me/84979630754";
  }

  handleMailButton = () => {
    window.location.href = "mailto:hydragroup.info@gmail.com";
  }

  handleSkypeButton = () => {
    // window.location.href = "skype:boy_never_die1811?chat";
    window.location.href = "skype:live:duong_tan_phuoc?chat";
  }

  handleLinkedInButton = () => {
    window.open("https://www.linkedin.com/in/hydra-group-5982b31a2/", "_blank");
  }

  handleYoutubeButton = () => {
    window.open("https://www.youtube.com/")
  }

  handleFacebookButton = () => {
    window.open("https://www.facebook.com/")
  }

  render() {
    return (
      <div className="Container">
        <img src={"https://hydra-cyborg.com/API/data/image/ImageMaintenance.png"} className="Maintenance" alt="Maintenance" />
        {/* <div className="HeaderContainer">
          <div>
            {this.state.isThuongMobile ? (
              <div className="SidebarShowCatalog">
                <div className="ContainLogoHydra">
                  <img src="https://hydra-cyborg.com/API/data/image/LogoHydra.png" className="LogoHydra" alt="LogoHydra" />
                </div>
                <div className="ContainButtonShowCatalog" onClick={this.handleShowCatalog}>
                  <MenuIcon sx={{ fontSize: 50, color: grey[600] }} />
                </div>
              </div>
            ) : null}
          </div>
          {(!this.state.isThuongMobile || this.state.isShowCatalog) && (
            <div className="Catalog">
              <div className="NameOfEachCatalog">
                <div className="ContainDesign3DButton" >
                  <table className="TableDesign3DButton">
                    <tr>
                      <td>
                        <button
                          className="Design3DButton"
                          onClick={this.handleDesign3DButton}
                        ><Trans>
                            3D Design
                          </Trans>
                        </button>
                      </td>
                    </tr>
                    <tr className='Design3DDetail'>{this.state.showDesign3DButton && (
                      <div className="ContainOptionsDesign3DButton">
                        <div className="OptionsDesign3DButton01">
                          <Trans>
                            Architecture
                          </Trans>
                        </div>
                        <div className="OptionsDesign3DButton02">
                          <Trans>
                            Mechanical
                          </Trans>
                        </div>
                        <div className="OptionsDesign3DButton03">
                          <Trans>
                            Mechatronic
                          </Trans>
                        </div>
                        <div className="OptionsDesign3DButton04">
                          <Trans>
                            Product
                          </Trans>
                        </div>
                      </div>
                    )}</tr>
                  </table>
                </div>

                <div className="ContainReverseEngineeringButton">
                  <button
                    className="ReverseEngineeringButton"
                  >
                    <Trans>
                      Reverse Engineering
                    </Trans>
                  </button>
                </div>

                <div className="ContainMachining3DButton" ref={(ButtonMachining3DRef) => (this.ButtonMachining3DRef = ButtonMachining3DRef)} >
                  <table>
                    <tr>
                      <td>
                        <button
                          className="Machining3DButton"
                          onClick={this.handleMachining3DButton}
                        ><Trans>
                            3D Machining
                          </Trans>
                        </button>
                      </td>
                    </tr>
                    <tr className='Machining3DDetail'>
                      {this.state.showMachining3DButton && (
                        <div className="ContainOptionsMachining3DButton">
                          <div className="OptionsMachining3DButton01">
                            <Trans>
                              3D Filament printing
                            </Trans>
                          </div>
                          <div className="OptionsMachining3DButton02">
                            <Trans>
                              DLP 3D Printing
                            </Trans>
                          </div>
                          <div className="OptionsMachining3DButton03">
                            <Trans>
                              3D CNC Milling
                            </Trans>
                          </div>
                          <div className="OptionsMachining3DButton04">
                            <Trans>
                              Laser Engraving
                            </Trans>
                          </div>
                          <div className="OptionsMachining3DButton05">
                            <Trans>
                              Plasma Cutting
                            </Trans>
                          </div>
                          <div className="OptionsMachining3DButton06">
                            <Trans>
                              Robot Arm
                            </Trans>
                          </div>
                        </div>
                      )}</tr>
                  </table>
                </div>

                <div className="ContainMoreSolutionsButton" ref={(ButtonMoreSolutionsRef) => (this.ButtonMoreSolutionsRef = ButtonMoreSolutionsRef)}>
                  <table>
                    <tr>
                      <td>
                        <button
                          className="MoreSolutionsButton"
                          onClick={this.handleMoreSolutionsButton}
                        >
                          <Trans>
                            MoreSolutions
                          </Trans>
                        </button>
                      </td>
                    </tr>
                    <tr className='MoreSolutionsDetail'>
                      {this.state.showMoreSolutionsButton && (
                        <div className="ContainOptionsMoreSolutionsButton">
                          <div className="OptionsMoreSolutionsButton01">
                            <Trans>
                              AI
                            </Trans>
                          </div>
                          <div className="OptionsMoreSolutionsButton02">
                            <Trans>
                              Image Processing
                            </Trans>
                          </div>
                        </div>
                      )}</tr>
                  </table>
                </div>

                <div className="ContainTrainingButton" ref={(ButtonTrainingRef) => (this.ButtonTrainingRef = ButtonTrainingRef)}>
                  <table>
                    <tr>
                      <td>
                        <button
                          className="TrainingButton"
                          onClick={this.handleTrainingButton}
                        >
                          <Trans>
                            Training
                          </Trans>
                        </button>
                      </td>
                    </tr>
                    <tr className='TrainingDetail'>
                      {this.state.showTrainingButton && (
                        <div className="ContainOptionsTrainingButton">
                          <div className="OptionsTrainingButton01">
                            <Trans>
                              ANSYS
                            </Trans>
                          </div>
                          <div className="OptionsTrainingButton02">
                            <Trans>
                              ABAQUS
                            </Trans>
                          </div>
                          <div className="OptionsTrainingButton03">
                            <Trans>
                              CATIA
                            </Trans>
                          </div>
                          <div className="OptionsTrainingButton04">
                            <Trans>
                              UNITY
                            </Trans>
                          </div>
                          <div className="OptionsTrainingButton05">
                            <Trans>
                              AI
                            </Trans>
                          </div>
                          <div className="OptionsTrainingButton06">
                            <Trans>
                              C++/Python/C#/HLSL/GLSL/JS/PHP Language
                            </Trans>
                          </div>
                        </div>
                      )}
                    </tr>
                  </table>
                </div>

                <div className="ContainServicesHeaderButton" ref={(ButtonServicesHeaderRef) => (this.ButtonServicesHeaderRef = ButtonServicesHeaderRef)}>
                  <table>
                    <tr>
                      <td>
                        <button
                          className="ServicesHeaderButton"
                          onClick={this.handleServicesHeaderButton}
                        >
                          <Trans>
                            Services
                          </Trans>
                        </button>
                      </td>
                    </tr>
                    <tr className='ServicesHeaderDetail'>
                      {this.state.showServicesHeaderButton && (
                        <div className="ContainOptionsServicesHeaderButton">
                          <div className="OptionsServicesHeaderButton01">
                            <Trans>
                              Simulation
                            </Trans>
                          </div>
                          <div className="OptionsServicesHeaderButton02">
                            <Trans>
                              VR/AR
                            </Trans>
                          </div>
                          <div className="OptionsServicesHeaderButton03">
                            <Trans>
                              Design 3D
                            </Trans>
                          </div>
                          <div className="OptionsServicesHeaderButton04">
                            <Trans>
                              Product Design
                            </Trans>
                          </div>
                          <div className="OptionsServicesHeaderButton05">
                            <Trans>
                              Prototype
                            </Trans>
                          </div>
                        </div>
                      )}
                    </tr>
                  </table>
                </div>

                <div className="ContainSupportButton" ref={(ButtonSupportRef) => (this.ButtonSupportRef = ButtonSupportRef)}>
                  <table>
                    <tr>
                      <td>
                        <button
                          className="SupportButton"
                          onClick={this.handleSupportButton}
                        >
                          <Trans>
                            Support
                          </Trans>
                        </button>
                      </td>
                    </tr>
                    <tr className='SupportDetail'>
                      {this.state.showSupportButton && (
                        <div className="ContainOptionsSupportButton">
                          <div className="OptionsSupportButton01">
                            <Trans>
                              Setup Server
                            </Trans>
                          </div>
                          <div className="OptionsSupportButton02">
                            <Trans>
                              Setup Cluster
                            </Trans>
                          </div>
                          <div className="OptionsSupportButton03">
                            <Trans>
                              Setup Network
                            </Trans>
                          </div>
                          <div className="OptionsSupportButton04">
                            <Trans>
                              System
                            </Trans>
                          </div>
                        </div>
                      )}
                    </tr>
                  </table>
                </div>

                <div className="ContainContactButton">
                  <button
                    className="ContactButton"
                    onClick={this.handleContactButton}
                  >
                    <Trans>
                      Contact
                    </Trans>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div> */}


        {/* <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/Architecture" element={<Architecture />} />
          <Route path="/Mechanical" element={<Mechanical />} />
          <Route path="/Mechatronic" element={<Mechatronic />} />
          <Route path="/Product" element={<Product />} />
          <Route path="/CNC3DMilling" element={<CNC3DMilling />} />
          <Route path="/DLP3DPrinting" element={<DLP3DPrinting />} />
          <Route path="/Filament3DPrinting" element={<Filament3DPrinting />} />
          <Route path="/LaserEngraving" element={<LaserEngraving />} />
          <Route path="/PlasmaCutting" element={<PlasmaCutting />} />
          <Route path="/RobotArm" element={<RobotArm />} />
          <Route path="/ImageProcessing" element={<ImageProcessing />} />
          <Route path="/MoreSolutionsAI" element={<MoreSolutionsAI />} />
          <Route path="/Design3D" element={<Design3D />} />
          <Route path="/ProductDesign" element={<ProductDesign />} />
          <Route path="/Prototype" element={<Prototype />} />
          <Route path="/Simulation" element={<Simulation />} />
          <Route path="/VR_AR" element={<VR_AR />} />
          <Route path="/SetupCluster" element={<SetupCluster />} />
          <Route path="/SetupNetwork" element={<SetupNetwork />} />
          <Route path="/SetupServer" element={<SetupServer />} />
          <Route path="/SupportSystem" element={<SupportSystem />} />
          <Route path="/Abaqus" element={<Abaqus />} />
          <Route path="/Ansys" element={<Ansys />} />
          <Route path="/Catia" element={<Catia />} />
          <Route path="/ProgrammingLanguage" element={<ProgrammingLanguage />} />
          <Route path="/TrainingAI" element={<TrainingAI />} />
          <Route path="/Unity" element={<Unity />} />
        </Routes> */}

        {/* <div className="AboutCompany">
          <div className="EmptySpaceAboutCompany">

          </div>

          <div className="AboutCompanyContent">
            <div className="AboutCompanyContentLeft">
              <div className="Resources">
                <div className="TitleResources">
                  <Trans>
                    RESOURCES
                  </Trans>
                </div>
                <div className="ResourcesContent01">
                  <Trans>
                    About Us
                  </Trans>
                </div>
                <div className="ResourcesContent02">
                  <Trans>
                    Application Mentoring Session
                  </Trans>
                </div>
                <div className="ResourcesContent03">
                  <Trans>
                    Blog
                  </Trans>
                </div>
                <div className="ResourcesContent04">
                  <Trans>
                    Calendar
                  </Trans>
                </div>
                <div className="ResourcesContent05">
                  <Trans>
                    Careers
                  </Trans>
                </div>
                <div className="ResourcesContent06">
                  <Trans>
                    Customer Portal
                  </Trans>
                </div>
                <div className="ResourcesContent07">
                  <Trans>
                    Customer Stories
                  </Trans>
                </div>
                <div className="ResourcesContent08">
                  <Trans>
                    Knowledge Base
                  </Trans>
                </div>
                <div className="ResourcesContent09">
                  <Trans>
                    Online Store
                  </Trans>
                </div>
                <div className="ResourcesContent10">
                  <Trans>
                    Training
                  </Trans>
                </div>
              </div>
              <div className="Services">
                <div className="TitleServices">
                  <Trans>
                    SERVICES
                  </Trans>
                </div>
                <div className="ServicesContent01">
                  <Trans>
                    Simulation
                  </Trans>
                </div>
                <div className="ServicesContent02">
                  <Trans>
                    VR/AR
                  </Trans>
                </div>
                <div className="ServicesContent03">
                  <Trans>
                    Design 3D
                  </Trans>
                </div>
                <div className="ServicesContent04">
                  <Trans>
                    Product Design
                  </Trans>
                </div>
                <div className="ServicesContent05">
                  <Trans>
                    Prototype
                  </Trans>
                </div>
                <div className="TitleServices">
                  <Trans>
                    SUPPORT
                  </Trans>
                </div>
                <div className="ServicesContent01">
                  <Trans>
                    Setup Server
                  </Trans>
                </div>
                <div className="ServicesContent02">
                  <Trans>
                    Setup Cluster
                  </Trans>
                </div>
                <div className="ServicesContent03">
                  <Trans>
                    Setup Network
                  </Trans>
                </div>
                <div className="ServicesContent04">
                  <Trans>
                    System
                  </Trans>
                </div>
              </div>
            </div> */}

            {/* ----------CONTACT---------- */}
            {/* <div className="AboutCompanyContentRight">
              <div className="Contact">
                <div className="TitleContact">
                  <Trans>
                    CONTACT
                  </Trans>
                </div>
                <div className="ContactHeader01">
                  <Trans>
                    General Inquiries
                  </Trans>
                </div>
                <div className="ContactContent01">
                  <span className="PhoneContact">
                    <Trans>
                      Phone: 0979630754
                    </Trans>
                  </span>
                </div>
                <div className="ContactContent02">
                  <span className="EmailContact">
                    Email: hydragroup.info@gmail.com
                  </span>
                </div>
                <div className="ContactHeader02">
                  <Trans>
                    Technical Support
                  </Trans>
                </div>
                <div className="ContactContent03">
                  <span className="PhoneSupport">
                    <Trans>
                      Phone: 0979630754
                    </Trans>
                  </span>
                </div>
                <div className="ContactContent04">
                  <span className="EmailSupport">
                    Email: hydragroup.info@gmail.com
                  </span>
                </div>
                <div className="ContactMap">
                  <a
                    className="TextLink"
                    target="_blank"
                    href="https://www.google.com/maps/place/C%C3%B4ng+Ty+Tnhh+Solink/@10.9283674,106.6462142,17z/data=!3m1!4b1!4m6!3m5!1s0x3174d468de395d01:0x9cea4e2b2b9dedac!8m2!3d10.9283622!4d106.6510851!16s%2Fg%2F11l668gp8k?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
                  >
                    <Trans>
                      Find a Location
                    </Trans>
                  </a>
                </div>
              </div>
              <div className="SignUpEmail">
                <div className="TitleSignUpEmail">
                  <Trans>
                    Stay up to date with HydraCyborg news!
                  </Trans>
                </div>
                <div className="ContainSignUpEmailContent">
                  <div className="SignUpEmailContent01">
                    Email
                  </div>
                  <div className="SignUpEmailContent02">
                    *
                  </div>
                </div>
                <div className="TextInput">
                  <input
                    type="email"
                    placeholder="Enter your email..."
                    className="InputField"
                    value={this.state.userEmail}
                    autoCapitalize="none"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="ContainButtonSubcribe">
                  {this.state.userEmail.length >= 11 ? <this.enableButtonSubcribe></this.enableButtonSubcribe> : <this.disableButtonSubcribe></this.disableButtonSubcribe>};
                </div>
                <div className="ContainNotificationText">
                  {this.state.notification && (
                    <p style={{ color: this.state.notificationColor }}>
                      {this.state.notification}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ContainerFooter">
          <div className="LeftContentFooter">
            <button className="FacebookButton" onClick={this.handleFacebookButton}>
            </button>
            <button className="YoutubeButton" onClick={this.handleYoutubeButton}>
            </button>
            <button className="LinkedInButton" onClick={this.handleLinkedInButton}>
            </button>
          </div>
          <div className="CenterContentFooter">
            <div className="ContainCountryButton">
              <button
                class="EnglandButton"
                onClick={() => this.changeLanguage("en")}
              >
              </button>

              <button
                class="VietNamButton"
                onClick={() => this.changeLanguage("vi")}
              >
              </button>

              <button
                class="KoreaButton"
              >
              </button>
            </div>
          </div>
          <div className="RightContentFooter">
            <p className="TextRightContentFooter">
              Copyright © 2016 - 2025 HYDRA-CYBORG. All rights reserved.
            </p>
          </div>
          <div className="IconList">
            <table className="IconListTable">
              {
                this.state.showContactList && (
                  <>
                    < tr >
                      <button
                        className="IconWhatsapp"
                        onClick={this.handleWhatsappButton}
                      >
                      </button>
                    </tr>
                    <tr>
                      <button
                        className="IconMessenger"
                        onClick={this.handleMessengerButton}
                      >
                      </button>
                    </tr>
                    <tr>
                      <button
                        className="IconMail"
                        onClick={this.handleMailButton}
                      >
                      </button>
                    </tr>
                    <tr>
                      <button
                        className="IconSkype"
                        onClick={this.handleSkypeButton}
                      >
                      </button>
                    </tr>
                    <tr>
                      <button
                        className="IconZalo"
                        onClick={this.handleZaloButton}
                      >
                      </button>
                    </tr>
                  </>
                )
              }
              <tr>
                <button className="ContactUsButton" onClick={this.handleShowContactList}>
                </button>
              </tr>
            </table>
          </div>
        </div> */}
      </div >
    );
  }
}