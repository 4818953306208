import React from 'react';
import './LaserEngraving.css';
import { Trans } from 'react-i18next';
import i18n from '../../i18n';

export default class LaserEngraving extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: "",
            isButtonSubcribeDisable: false,
        };
    }

    handleInputChange = (e) => {
        this.setState({ userEmail: e.target.value });
    };

    handleSubcribeButton = async () => {
        this.setState({
            notification: "Sending email...",
            userEmail: "",
        });

        const { userEmail } = this.state;

        try {
            await fetch("https://hydra-cyborg.com/API/data/api/SendEmail.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json"
                },
                body: JSON.stringify({
                    user_email: userEmail,
                })
            })
                .then(res => res.json())
                .then(async response => {
                    if (response.result == "0") {

                        this.setState({ notification: response.message });

                        setTimeout(() => {
                            this.setState({ isButtonSubcribeDisable: true });
                            this.setState({ notificationColor: "red" });
                            this.setState({ notification: "" });
                        }, 3000);

                    } else if (response.result == "1") {

                        this.setState({
                            notification: response.message,
                            notificationColor: "green",
                        });

                        setTimeout(() => {
                            this.setState({ isButtonSubcribeDisable: true });
                            this.setState({ notificationColor: "red" });
                            this.setState({ notification: "" });
                        }, 3000);

                    } else if (response.result == "2") {

                        this.setState({ notification: response.message });

                        setTimeout(() => {
                            this.setState({ notification: "" });
                            this.setState({ isButtonSubcribeDisable: true });
                            this.setState({ notificationColor: "red" });
                        }, 3000);

                    }
                })
                .catch(error => {
                    this.setState({ notification: "Please try again !" });

                    setTimeout(() => {
                        this.setState({ notification: "" });
                        this.setState({ isButtonSubcribeDisable: true });
                        this.setState({ notificationColor: "red" });
                    }, 3000);
                    console.log("Lỗi:", error);
                });
        } catch (error) {
            this.setState({ notification: "System error, please try again!" });

            setTimeout(() => {
                this.setState({ notification: "" });
                this.setState({ isButtonSubcribeDisable: true });
                this.setState({ notificationColor: "red" });
            }, 3000);
            console.log("Lỗi:", error);
        };
    }

    enableButtonSubcribe = () => {
        return (
            <button
                name="subcribe"
                className="EnableButtonSubcribe"
                onClick={this.handleSubcribeButton}
                disabled={false}
            >
                <Trans>
                    SUBCRIBE
                </Trans>
            </button>
        )
    }

    disableButtonSubcribe = () => {
        return (
            <button
                name="subcribe"
                className="DisableButtonSubcribe"
                disabled={this.state.isButtonSubcribeDisable}
            >
                <Trans>
                    SUBCRIBE
                </Trans>
            </button>
        )
    }

    changeLanguage(lng) {
        console.log("Click change" + lng);
        this.setState({ languageState: lng });
        // const { i18n } = this.props;
        i18n.changeLanguage(lng); // Thay đổi ngôn ngữ
    };

    handleShowContactList = () => {
        this.setState(prevState => ({ showContactList: !prevState.showContactList }));
    };

    handleWhatsappButton = () => {
        window.location.href = "https://wa.me/84979630754";
    }

    handleMessengerButton = () => {
        window.location.href = "https://m.me/vanle090603";
    }

    handleZaloButton = () => {
        window.location.href = "https://zalo.me/84979630754";
    }

    handleMailButton = () => {
        window.location.href = "mailto:hydragroup.info@gmail.com";
    }

    handleSkypeButton = () => {
        // window.location.href = "skype:boy_never_die1811?chat";
        window.location.href = "skype:live:duong_tan_phuoc?chat";
    }

    handleLinkedInButton = () => {
        window.open("https://www.linkedin.com/in/hydra-group-5982b31a2/", "_blank");
    }

    handleYoutubeButton = () => {
        window.open("https://www.youtube.com/")
    }

    handleFacebookButton = () => {
        window.open("https://www.facebook.com/")
    }

    render() {
        return (
            <div className="architecture">
                <h1>LaserEngraving</h1>
            </div>
        );
    }
}