import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
    resources: {
        en: {
            translations: {
                "3D Design": "3D Design",
                "Architecture": "Architecture",
                "Mechanical": "Mechanical",
                "Mechatronic": "Mechatronic",
                "Product": "Product",
                "Vehicles Available": "Vehicles Available",
                "3D Machining": "3D Machining",
                "3D Filament printing": "3D Filament printing",
                "DLP 3D Printing": "DLP 3D Printing",
                "3D CNC Milling": "3D CNC Milling",
                "Laser Engraving": "Laser Engraving",
                "Plasma Cutting": "Plasma Cutting",
                "Robot Arm": "Robot Arm",
                "MoreSolutions": "MoreSolutions",
                "AI": "AI",
                "Image Processing": "Image Processing",
                "Training": "Training",
                "ANSYS": "ANSYS",
                "ABAQUS": "ABAQUS",
                "CATIA": "CATIA",
                "UNITY": "UNITY",
                "C++/Python/C#/HLSL/GLSL/JS/PHP Language": "C++/Python/C#/HLSL/GLSL/JS/PHP Language",
                "Services": "Services",
                "Simulation": "Simulation",
                "VR/AR": "VR/AR",
                "Design 3D": "Design 3D",
                "Product Design": "Product Design",
                "Prototype": "Prototype",
                "Support": "Support",
                "Setup Server": "Setup Server",
                "Setup Cluster": "Setup Cluster",
                "Setup Network": "Setup Network",
                "System": "System",
                "Contact": "Contact",
                "SIMULATION SERVICES": "SIMULATION SERVICES",
                "Advanced VR/AR - FEA & CFD for better Production Engineering": "Advanced VR/AR - FEA & CFD for better Production Engineering",
                "Read More...": "Read More...",
                "DESIGN BETTER PRODUCTS WITH CAE EXPERTS": "DESIGN BETTER PRODUCTS WITH CAE EXPERTS",
                "Bringing an innovative product to life is no easy task. Engineers are tasked with creating something entirely unique while ensuring it works flawlessly every time.": "Bringing an innovative product to life is no easy task. Engineers are tasked with creating something entirely unique while ensuring it works flawlessly every time.",
                "It's a significant challenge.": "It's a significant challenge.",
                "How do you reduce these risks effectively? Overlooking them can lead to serious setbacks: delays in production, lost business opportunities, failures in real-world use, costly recalls, and reputational harm.": "How do you reduce these risks effectively? Overlooking them can lead to serious setbacks: delays in production, lost business opportunities, failures in real-world use, costly recalls, and reputational harm.",
                "With powerful of CAE, FEA, CFD, numerical modeling, virtual testing, or prototyping, the objective is the same: achieving smarter engineering decisions through advanced simulation technologies.": "With the power of CAE, FEA, CFD, numerical modeling, virtual testing, or prototyping, the objective is the same: achieving smarter engineering decisions through advanced simulation technologies.",
                "SIMULATION SERVICE CAPABILITIES": "SIMULATION SERVICE CAPABILITIES",
                "FEA Analysis": "FEA Analysis",
                "Read more...": "Read more...",
                "Read less.": "Read less.",
                "We provide professional Finite Element Analysis (FEA) simulation services to help you solve complex engineering challenges. Our expertise in tools like ANSYS, ABAQUS, and HyperWorks allows us to analyze structural strength, stress, thermal performance, and dynamic behavior with precision.Whether you need to optimize product designs, enhance durability, reduce material costs, or improve performance under real-world conditions, our FEA services deliver actionable insights. Partner with us to accelerate your design process, minimize risks, and achieve the best results for your projects.": "We provide professional Finite Element Analysis (FEA) simulation services to help you solve complex engineering challenges. Our expertise in tools like ANSYS, ABAQUS, and HyperWorks allows us to analyze structural strength, stress, thermal performance, and dynamic behavior with precision.Whether you need to optimize product designs, enhance durability, reduce material costs, or improve performance under real-world conditions, our FEA services deliver actionable insights. Partner with us to accelerate your design process, minimize risks, and achieve the best results for your projects.",
                "We can achieve almost anything with simulation technology. With expertise in FEM, DEM, CFD, and skills in CAD/CAM/CAE/Programming, we provide comprehensive simulation solutions.": "We can achieve almost anything with simulation technology. With expertise in FEM, DEM, CFD, and skills in CAD/CAM/CAE/Programming, we provide comprehensive simulation solutions.",
                "CFD Analysis": "CFD Analysis",
                "For example, if you want to understand how the concrete mixing process works, we can use CFD (ANSYS Fluent) to simulate it. To enhance the strength of a bike frame, we can perform FEA to analyze stress and design reinforcement ribs. If reducing the cost and weight of a wind turbine is your goal, we can create an optimized simulation process using ANSYS or HyperWorks. For wave energy converters, we can analyze and optimize efficiency using ANSYS AQWA. Even for scenarios like impact analysis (e.g., car crashes) or drop tests (e.g., for iPhones), we can leverage tools like ANSYS Dynamic or ABAQUS Dynamic.": "For example, if you want to understand how the concrete mixing process works, we can use CFD (ANSYS Fluent) to simulate it. To enhance the strength of a bike frame, we can perform FEA to analyze stress and design reinforcement ribs. If reducing the cost and weight of a wind turbine is your goal, we can create an optimized simulation process using ANSYS or HyperWorks. For wave energy converters, we can analyze and optimize efficiency using ANSYS AQWA. Even for scenarios like impact analysis (e.g., car crashes) or drop tests (e.g., for iPhones), we can leverage tools like ANSYS Dynamic or ABAQUS Dynamic.",
                "Every physical phenomenon in the real world can be analyzed in a virtual environment, providing solutions tailored to your needs.": "Every physical phenomenon in the real world can be analyzed in a virtual environment, providing solutions tailored to your needs.",
                "SIMULATION SERVICE TYPES": "SIMULATION SERVICE TYPES",
                "UNITY - GAME DEVELOP": "UNITY - GAME DEVELOP",
                "Whether you're building casual mobile games, immersive virtual reality experiences, or large-scale open-world adventures, Unity3D delivers the tools and resources needed to turn ideas into reality efficiently. Allowing games to be deployed seamlessly across PC, consoles, mobile devices, and web browsers. Unity3D provides a robust physics engine, high-quality rendering, and a vast asset store, making it easier to bring creative visions to life.": "Whether you're building casual mobile games, immersive virtual reality experiences, or large-scale open-world adventures, Unity3D delivers the tools and resources needed to turn ideas into reality efficiently. Allowing games to be deployed seamlessly across PC, consoles, mobile devices, and web browsers. Unity3D provides a robust physics engine, high-quality rendering, and a vast asset store, making it easier to bring creative visions to life.",
                "Language : C#, WGSL, HLSL, Python, PHP": "Language : C#, WGSL, HLSL, Python, PHP",
                "3D modelling: Blender 4.0": "3D modelling: Blender 4.0",
                "Prototype - EV": "Prototype - EV",
                "We offer comprehensive services for the design, simulation, and prototyping of electric vehicles (EVs). From conceptual design and 3D modeling to performance simulations and manufacturing prototypes, our team ensures every aspect of your EV project is optimized for efficiency, safety, and innovation.": "We offer comprehensive services for the design, simulation, and prototyping of electric vehicles (EVs). From conceptual design and 3D modeling to performance simulations and manufacturing prototypes, our team ensures every aspect of your EV project is optimized for efficiency, safety, and innovation.",
                "Using advanced tools like CAD, FEA, and CFD, we analyze structural integrity, aerodynamics, and thermal management to deliver cutting-edge solutions. Whether you're developing a new EV model or refining an existing design, we provide end-to-end support to bring your vision to reality and stay ahead in the rapidly evolving electric vehicle industry.": "Using advanced tools like CAD, FEA, and CFD, we analyze structural integrity, aerodynamics, and thermal management to deliver cutting-edge solutions. Whether you're developing a new EV model or refining an existing design, we provide end-to-end support to bring your vision to reality and stay ahead in the rapidly evolving electric vehicle industry.",
                "UNITY - VR": "UNITY - VR",
                "We offer cutting-edge Unity and VR development services to help bring your ideas to life. Using Unity's powerful game engine, we create immersive virtual reality experiences tailored to your needs, whether for gaming, training simulations, architectural visualization, or interactive marketing.": "We offer cutting-edge Unity and VR development services to help bring your ideas to life. Using Unity's powerful game engine, we create immersive virtual reality experiences tailored to your needs, whether for gaming, training simulations, architectural visualization, or interactive marketing.",
                "Our expertise ensures seamless performance, realistic environments, and engaging interactions that captivate your audience. From concept to deployment, we work closely with you to deliver innovative VR solutions that exceed expectations and provide lasting impact. Let us help you transform your vision into a dynamic virtual experience!": "Our expertise ensures seamless performance, realistic environments, and engaging interactions that captivate your audience. From concept to deployment, we work closely with you to deliver innovative VR solutions that exceed expectations and provide lasting impact. Let us help you transform your vision into a dynamic virtual experience!",
                "PCB - Design Prototype": "PCB - Design Prototype",
                "We specialize in designing and manufacturing custom electronic circuits using AVR, PIC, STM32, ESP32, and embedded systems like Raspberry Pi. Our services include:": "We specialize in designing and manufacturing custom electronic circuits using AVR, PIC, STM32, ESP32, and embedded systems like Raspberry Pi. Our services include:",
                "Tailored circuit design to meet your project needs.": "Tailored circuit design to meet your project needs.",
                "Fabrication of high-quality 2-layer PCBs with precision.": "Fabrication of high-quality 2-layer PCBs with precision.",
                "Seamless integration of embedded systems and microcontrollers.": "Seamless integration of embedded systems and microcontrollers.",
                "Let us help bring your ideas to life with our expertise in electronics and innovation.": "Let us help bring your ideas to life with our expertise in electronics and innovation.",
                "Simulation Services Process": "Simulation Services Process",
                "CONSULTATION": "CONSULTATION",
                "PROPOSAL": "PROPOSAL",
                "COMMUNICATION": "COMMUNICATION",
                "EXECUTION": "EXECUTION",
                "DELIVERABLES": "DELIVERABLES",
                "NEXTSTEPS": "NEXTSTEPS",
                "It Begins with a Conversation": "It Begins with a Conversation",
                "INDUSTRIES SERVED": "INDUSTRIES SERVED",
                "WHO CAN WE HELP ?": "WHO CAN WE HELP ?",
                "We work across a broad range of industries that includes automotive, energy, oil and gas, aerospace and defense, civil and structural engineering, metals, machining and manufacturing, consumer goods and appliances, healthcare, and biomechanics; helping customers to leverage existing legacy and emerging simulation technologies.": "We work across a broad range of industries that includes automotive, energy, oil and gas, aerospace and defense, civil and structural engineering, metals, machining and manufacturing, consumer goods and appliances, healthcare, and biomechanics; helping customers to leverage existing legacy and emerging simulation technologies.",
                "We have experts in engineering, mathematics, and physics with proven experience in mechanical and structural engineering, metallurgy, computational fluid dynamics, high & low-frequency electromagnetism, multi-physics, multi-body dynamics, acoustics, tolerance management, and additive manufacturing.": "We have experts in engineering, mathematics, and physics with proven experience in mechanical and structural engineering, metallurgy, computational fluid dynamics, high & low-frequency electromagnetism, multi-physics, multi-body dynamics, acoustics, tolerance management, and additive manufacturing.",
                "REQUEST MORE INFORMATION": "REQUEST MORE INFORMATION",
                "RESOURCES": "RESOURCES",
                "About Us": "About Us",
                "Application Mentoring Session": "Application Mentoring Session",
                "Blog": "Blog",
                "Calendar": "Calendar",
                "Careers": "Careers",
                "Customer Portal": "Customer Portal",
                "Customer Stories": "Customer Stories",
                "Knowledge Base": "Knowledge Base",
                "Online Store": "Online Store",
                "CONTACT": "CONTACT",
                "General Inquiries": "General Inquiries",
                "Phone: 0979630754": "Phone: 0979630754",
                "Technical Support": "Technical Support",
                "Find a Location": "Find a Location",
                "Stay up to date with HydraCyborg news!": "Stay up to date with HydraCyborg news!",
                "SERVICES": "SERVICES",
                "SUPPORT": "SUPPORT",
                "SUBCRIBE": "SUBCRIBE",
            }
        },
        vi: {
            translations: {
                "3D Design": "Thiết Kế 3D",
                "Architecture": "Kiến trúc",
                "Mechanical": "Cơ khí",
                "Mechatronic": "Cơ điện tử",
                "Product": "Sản phẩm",
                "Reverse Engineering": "Thiết Kế Ngược",
                "3D Machining": "Gia Công 3D",
                "3D Filament printing": "In sợi 3D",
                "DLP 3D Printing": "In 3D bằng công nghệ DLP",
                "3D CNC Milling": "Gia công phay CNC 3D",
                "Laser Engraving": "Khắc Laser",
                "Plasma Cutting": "Cắt Plasma",
                "Robot Arm": "Cánh tay Robot",
                "MoreSolutions": "Giải Pháp Khác",
                "AI": "Trí tuệ nhân tạo",
                "Image Processing": "Xử lý ảnh",
                "Training": "Đào Tạo",
                "ANSYS": "Phần mềm mô phỏng ANSYS",
                "ABAQUS": "Phần mềm mô phỏng ABAQUS",
                "CATIA": "Phần mềm thiết kế và mô phỏng CATIA",
                "UNITY": "Phần mềm phát triển game UNITY",
                "C++/Python/C#/HLSL/GLSL/JS/PHP Language": "Ngôn ngữ lập trình C++/Python/C#/HLSL/GLSL/JS/PHP",
                "Services": "Dịch Vụ",
                "Simulation": "Mô phỏng",
                "VR/AR": "Thực tế ảo/Thực tế tăng cường",
                "Design 3D": "Thiết kế 3D",
                "Product Design": "Thiết kế sản phẩm",
                "Prototype": "Nguyên mẫu",
                "Support": "Hỗ Trợ",
                "Setup Server": "Thiết lập máy chủ",
                "Setup Cluster": "Thiết lập cụm máy chủ",
                "Setup Network": "Thiết lập mạng",
                "System": "Hệ thống",
                "Contact": "Liên Hệ",
                "SIMULATION SERVICES": "DỊCH VỤ MÔ PHỎNG",
                "Advanced VR/AR - FEA & CFD for better Production Engineering": "VR/AR Tiên Tiến - Phân Tích Phần Tử Hữu Hạn (FEA) và Phân Tích Dòng Chảy (CFD) để Cải Thiện Kỹ Thuật Sản Xuất",
                "Read More...": "Xem Thêm...",
                "DESIGN BETTER PRODUCTS WITH CAE EXPERTS": "THIẾT KẾ SẢN PHẨM TỐT HƠN VỚI CÁC CHUYÊN GIA CAE",
                "Bringing an innovative product to life is no easy task. Engineers are tasked with creating something entirely unique while ensuring it works flawlessly every time.": "Việc mang một sản phẩm sáng tạo vào cuộc sống không phải là một nhiệm vụ dễ dàng. Các kỹ sư được giao nhiệm vụ tạo ra một thứ hoàn toàn độc đáo trong khi đảm bảo nó hoạt động hoàn hảo mọi lúc.",
                "It's a significant challenge.": "Đó là một thách thức lớn.",
                "How do you reduce these risks effectively? Overlooking them can lead to serious setbacks: delays in production, lost business opportunities, failures in real-world use, costly recalls, and reputational harm.": "Làm thế nào để giảm thiểu những rủi ro này một cách hiệu quả? Bỏ qua chúng có thể dẫn đến những hậu quả nghiêm trọng: trì hoãn trong sản xuất, mất cơ hội kinh doanh, thất bại khi sử dụng thực tế, thu hồi tốn kém và tổn hại danh tiếng.",
                "With powerful of CAE, FEA, CFD, numerical modeling, virtual testing, or prototyping, the objective is the same: achieving smarter engineering decisions through advanced simulation technologies.": "Với sức mạnh của CAE, FEA, CFD, mô hình hóa số, thử nghiệm ảo hoặc tạo mẫu, mục tiêu là giống nhau: đạt được những quyết định kỹ thuật thông minh hơn thông qua công nghệ mô phỏng tiên tiến.",
                "SIMULATION SERVICE CAPABILITIES": "KHẢ NĂNG CỦA DỊCH VỤ MÔ PHỎNG",
                "FEA Analysis": "Phân tích phần tử hữu hạn (FEA)",
                "Read more...": "Xem thêm...",
                "Read less.": "Thu gọn.",
                "We provide professional Finite Element Analysis (FEA) simulation services to help you solve complex engineering challenges. Our expertise in tools like ANSYS, ABAQUS, and HyperWorks allows us to analyze structural strength, stress, thermal performance, and dynamic behavior with precision.Whether you need to optimize product designs, enhance durability, reduce material costs, or improve performance under real-world conditions, our FEA services deliver actionable insights. Partner with us to accelerate your design process, minimize risks, and achieve the best results for your projects.": "Chúng tôi cung cấp dịch vụ mô phỏng Phân tích phần tử hữu hạn (FEA) chuyên nghiệp để giúp bạn giải quyết những thách thức kỹ thuật phức tạp. Với chuyên môn sử dụng các công cụ như ANSYS, ABAQUS và HyperWorks, chúng tôi có thể phân tích độ bền cấu trúc, ứng suất, hiệu suất nhiệt và hành vi động lực học với độ chính xác cao. Dù bạn cần tối ưu hóa thiết kế sản phẩm, cải thiện độ bền, giảm chi phí vật liệu hay nâng cao hiệu suất trong các điều kiện thực tế, dịch vụ FEA của chúng tôi sẽ cung cấp những thông tin hữu ích. Hợp tác với chúng tôi để tăng tốc quy trình thiết kế, giảm thiểu rủi ro và đạt được kết quả tốt nhất cho các dự án của bạn.",
                "We can achieve almost anything with simulation technology. With expertise in FEM, DEM, CFD, and skills in CAD/CAM/CAE/Programming, we provide comprehensive simulation solutions.": "Chúng tôi có thể đạt được hầu hết mọi thứ với công nghệ mô phỏng. Với chuyên môn trong FEM, DEM, CFD và kỹ năng trong CAD/CAM/CAE/Lập trình, chúng tôi cung cấp các giải pháp mô phỏng toàn diện.",
                "CFD Analysis": "Phân tích CFD",
                "For example, if you want to understand how the concrete mixing process works, we can use CFD (ANSYS Fluent) to simulate it. To enhance the strength of a bike frame, we can perform FEA to analyze stress and design reinforcement ribs. If reducing the cost and weight of a wind turbine is your goal, we can create an optimized simulation process using ANSYS or HyperWorks. For wave energy converters, we can analyze and optimize efficiency using ANSYS AQWA. Even for scenarios like impact analysis (e.g., car crashes) or drop tests (e.g., for iPhones), we can leverage tools like ANSYS Dynamic or ABAQUS Dynamic.": "Ví dụ, nếu bạn muốn hiểu cách quá trình trộn bê tông hoạt động, chúng tôi có thể sử dụng CFD (ANSYS Fluent) để mô phỏng quá trình này. Để tăng cường độ bền của khung xe đạp, chúng tôi có thể thực hiện FEA để phân tích ứng suất và thiết kế các sườn gia cường. Nếu mục tiêu của bạn là giảm chi phí và trọng lượng của tua-bin gió, chúng tôi có thể tạo ra một quy trình mô phỏng tối ưu hóa bằng ANSYS hoặc HyperWorks. Đối với các bộ chuyển đổi năng lượng sóng, chúng tôi có thể phân tích và tối ưu hóa hiệu quả sử dụng ANSYS AQWA. Ngay cả trong các tình huống như phân tích va chạm (ví dụ: tai nạn xe hơi) hoặc thử nghiệm rơi (ví dụ: đối với iPhone), chúng tôi có thể tận dụng các công cụ như ANSYS Dynamic hoặc ABAQUS Dynamic.",
                "Every physical phenomenon in the real world can be analyzed in a virtual environment, providing solutions tailored to your needs.": "Mọi hiện tượng vật lý trong thế giới thực đều có thể được phân tích trong môi trường ảo, cung cấp các giải pháp phù hợp với nhu cầu của bạn.",
                "SIMULATION SERVICE TYPES": "CÁC LOẠI DỊCH VỤ MÔ PHỎNG",
                "UNITY - GAME DEVELOP": "UNITY - PHÁT TRIỂN GAME",
                "Whether you're building casual mobile games, immersive virtual reality experiences, or large-scale open-world adventures, Unity3D delivers the tools and resources needed to turn ideas into reality efficiently. Allowing games to be deployed seamlessly across PC, consoles, mobile devices, and web browsers. Unity3D provides a robust physics engine, high-quality rendering, and a vast asset store, making it easier to bring creative visions to life.": "Dù bạn đang xây dựng các trò chơi di động đơn giản, trải nghiệm thực tế ảo sống động, hay các cuộc phiêu lưu thế giới mở quy mô lớn, Unity3D cung cấp các công cụ và tài nguyên cần thiết để biến ý tưởng thành hiện thực một cách hiệu quả. Nó cho phép trò chơi được triển khai liền mạch trên PC, các hệ máy console, thiết bị di động và trình duyệt web. Unity3D cung cấp một động cơ vật lý mạnh mẽ, khả năng dựng hình chất lượng cao và một cửa hàng tài sản rộng lớn, giúp việc hiện thực hóa các ý tưởng sáng tạo trở nên dễ dàng hơn.",
                "Language : C#, WGSL, HLSL, Python, PHP": "Ngôn ngữ : C#, WGSL, HLSL, Python, PHP",
                "3D modelling: Blender 4.0": "Mô hình 3D: Blender 4.0.",
                "Prototype - EV": "Nguyên mẫu - Xe điện",
                "We offer comprehensive services for the design, simulation, and prototyping of electric vehicles (EVs). From conceptual design and 3D modeling to performance simulations and manufacturing prototypes, our team ensures every aspect of your EV project is optimized for efficiency, safety, and innovation.": "Chúng tôi cung cấp dịch vụ toàn diện cho việc thiết kế, mô phỏng và tạo mẫu xe điện (EV). Từ thiết kế khái niệm và mô hình 3D đến mô phỏng hiệu suất và tạo mẫu sản xuất, đội ngũ của chúng tôi đảm bảo mọi khía cạnh của dự án xe điện của bạn đều được tối ưu hóa cho hiệu quả, an toàn và đổi mới.",
                "Using advanced tools like CAD, FEA, and CFD, we analyze structural integrity, aerodynamics, and thermal management to deliver cutting-edge solutions. Whether you're developing a new EV model or refining an existing design, we provide end-to-end support to bring your vision to reality and stay ahead in the rapidly evolving electric vehicle industry.": "Sử dụng các công cụ tiên tiến như CAD, FEA và CFD, chúng tôi phân tích tính toàn vẹn cấu trúc, khí động học và quản lý nhiệt để cung cấp các giải pháp tiên tiến. Dù bạn đang phát triển một mẫu xe điện mới hay hoàn thiện thiết kế hiện có, chúng tôi cung cấp hỗ trợ toàn diện để hiện thực hóa tầm nhìn của bạn và giữ vững vị thế dẫn đầu trong ngành công nghiệp xe điện đang phát triển nhanh chóng.",
                "UNITY - VR": "UNITY - THỰC TẾ ẢO (VR)",
                "We offer cutting-edge Unity and VR development services to help bring your ideas to life. Using Unity's powerful game engine, we create immersive virtual reality experiences tailored to your needs, whether for gaming, training simulations, architectural visualization, or interactive marketing.": "Chúng tôi cung cấp các dịch vụ phát triển Unity và VR tiên tiến để giúp hiện thực hóa ý tưởng của bạn. Sử dụng engine trò chơi mạnh mẽ của Unity, chúng tôi tạo ra các trải nghiệm thực tế ảo sống động phù hợp với nhu cầu của bạn, dù là trò chơi, mô phỏng đào tạo, trực quan hóa kiến trúc hoặc marketing tương tác (phương pháp marketing 1-1).",
                "Our expertise ensures seamless performance, realistic environments, and engaging interactions that captivate your audience. From concept to deployment, we work closely with you to deliver innovative VR solutions that exceed expectations and provide lasting impact. Let us help you transform your vision into a dynamic virtual experience!": "Chuyên môn của chúng tôi đảm bảo hiệu suất liền mạch, môi trường thực tế và tương tác hấp dẫn để thu hút người dùng. Từ khái niệm đến triển khai, chúng tôi làm việc chặt chẽ với bạn để cung cấp các giải pháp VR sáng tạo vượt xa mong đợi và tạo ra ảnh hưởng lâu dài. Hãy để chúng tôi giúp bạn biến tầm nhìn của bạn thành một trải nghiệm sống động!",
                "PCB - Design Prototype": "PCB - Thiết kế nguyên mẫu",
                "We specialize in designing and manufacturing custom electronic circuits using AVR, PIC, STM32, ESP32, and embedded systems like Raspberry Pi. Our services include:": "Chúng tôi chuyên thiết kế và sản xuất các mạch điện tử tùy chỉnh sử dụng AVR, PIC, STM32, ESP32, và các hệ thống nhúng như Raspberry Pi. Dịch vụ của chúng tôi bao gồm:",
                "Tailored circuit design to meet your project needs.": "Thiết kế mạch tùy chỉnh để đáp ứng nhu cầu dự án của bạn.",
                "Fabrication of high-quality 2-layer PCBs with precision.": "Chế tạo PCB 2 lớp chất lượng cao với độ chính xác.",
                "Seamless integration of embedded systems and microcontrollers.": "Tích hợp mượt mà hệ thống nhúng và vi điều khiển.",
                "Let us help bring your ideas to life with our expertise in electronics and innovation.": "Hãy để chúng tôi giúp hiện thực hóa ý tưởng của bạn với chuyên môn của chúng tôi trong lĩnh vực điện tử và sáng tạo.",
                "Simulation Services Process": "Quy Trình Dịch Vụ Mô Phỏng",
                "CONSULTATION": "TƯ VẤN",
                "PROPOSAL": "ĐỀ XUẤT",
                "COMMUNICATION": "GIAO TIẾP",
                "EXECUTION": "THỰC HIỆN",
                "DELIVERABLES": "SẢN PHẨM",
                "NEXTSTEPS": "BƯỚC TIẾP THEO",
                "It Begins with a Conversation": "Bắt Đầu Bằng Một Cuộc Trò Chuyện",
                "INDUSTRIES SERVED": "CÁC NGÀNH CÔNG NGHIỆP PHỤC VỤ",
                "WHO CAN WE HELP ?": "CHÚNG TÔI CÓ THỂ GIÚP AI ?",
                "We work across a broad range of industries that includes automotive, energy, oil and gas, aerospace and defense, civil and structural engineering, metals, machining and manufacturing, consumer goods and appliances, healthcare, and biomechanics; helping customers to leverage existing legacy and emerging simulation technologies.": "Chúng tôi hoạt động trong một loạt các ngành công nghiệp bao gồm ô tô, năng lượng, dầu khí, hàng không và quốc phòng, kỹ thuật dân dụng và kết cấu, kim loại, gia công và sản xuất, hàng tiêu dùng và thiết bị gia dụng, chăm sóc sức khỏe, và sinh học cơ học; giúp khách hàng tận dụng các công nghệ mô phỏng hiện có và mới nổi.",
                "We have experts in engineering, mathematics, and physics with proven experience in mechanical and structural engineering, metallurgy, computational fluid dynamics, high & low-frequency electromagnetism, multi-physics, multi-body dynamics, acoustics, tolerance management, and additive manufacturing.": "Chúng tôi có các chuyên gia trong các lĩnh vực kỹ thuật, toán học và vật lý với kinh nghiệm đã được chứng minh trong kỹ thuật cơ khí và kết cấu, luyện kim, động lực học chất lưu tính toán, điện từ học tần số cao và thấp, đa vật lý, động học đa thể, âm học, quản lý dung sai và sản xuất phụ gia.",
                "REQUEST MORE INFORMATION": "YÊU CẦU THÊM THÔNG TIN",
                "RESOURCES": "TÀI NGUYÊN",
                "About Us": "Về Chúng Tôi",
                "Application Mentoring Session": "Phiên Hướng Dẫn Ứng Dụng",
                "Blog": "Nhật ký trực tuyến",
                "Calendar": "Lịch",
                "Careers": "Cơ hội nghề nghiệp",
                "Customer Portal": "Cổng thông tin khách hàng",
                "Customer Stories": "Câu chuyện khách hàng",
                "Knowledge Base": "Kho tri thức",
                "Online Store": "Cửa hàng trực tuyến",
                "CONTACT": "LIÊN HỆ",
                "General Inquiries": "Thông tin chung",
                "Phone: 0979630754": "Điện thoại: 0979630754",
                "Technical Support": "Hỗ trợ kỹ thuật",
                "Find a Location": "Tìm vị trí",
                "Stay up to date with HydraCyborg news!": "Cập nhật tin tức mới nhất từ HydraCyborg!",
                "SERVICES": "DỊCH VỤ",
                "SUPPORT": "HỖ TRỢ",
                "SUBCRIBE": "ĐĂNG KÝ",
            }
        },
    },
    fallbackLng: "en", // default language
    debug: false,

    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false,

    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;